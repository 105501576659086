/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCozUibKrdtV0_vg4h8Cezd6RbVDp0wZro",
  "appId": "1:465387947723:web:ce488689500081f22bfd4e",
  "authDomain": "schooldog-i-trion-ga.firebaseapp.com",
  "measurementId": "G-TW146PLZQP",
  "messagingSenderId": "465387947723",
  "project": "schooldog-i-trion-ga",
  "projectId": "schooldog-i-trion-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-trion-ga.appspot.com"
}
